(function($) {
    var applicationForm = $("form.job.collapse");

    //function to return number of required fields within a block
    function getRequiredFields( block ) {
        _fields = block.find('.frm_required_field [type="radio"], .frm_required_field [type="text"]:visible, .frm_required_field [type="email"], .frm_required_field select, .frm_required_field textarea');
        return _fields;
    }

    //function to count number of inputs in block
    function countInputs( fields ) {
        _fieldCount = fields.length;
        return _fieldCount;
    }

    //check if mandatory fields are valid
    function mandatoryFields( block ) {
        //return value of required fields within the block which are "YES"
        var _valid = getRequiredFields( block ).filter(function(k, v) {
            return $(v).val() == "Yes";
        }).length;

        var _wrong = getRequiredFields( block ).filter(function(k, v) {
            return $(v).val() == "No";
        }).length;

        var error_div = block.find('.frm_error');
        //count whether the valid number of fields is equal to the number of required fields
        if (_valid == countInputs(getRequiredFields( block ))) {
            error_div.slideUp();
            return true;
        } else if (_valid + _wrong == countInputs(getRequiredFields( block ))) {
            error_div.slideDown();
        }
    }

    //check if required fields are filled in
    function isComplete( block ) {
        //return value of required fields within the block which are blank
        var _valid = getRequiredFields( block ).filter(function(k, v) {
            if($(v).prop('type') === 'radio') {
                if($('input[name="' + $(v).prop('name') + '"]:checked').length) {
                    return true;
                }
                return false;
            }
            return $(v).val() != "";
        }).length;

        //count whether the valid number of fields is equal to the number of required fields
        if (_valid == countInputs(getRequiredFields( block ))) {
            //alert('All fields are valid');
            return true;
        }
    }

    //function to expand next section, pass through current
    function showNextSection( block ) {
        if (block.hasClass('active')) {

            var _next = block.next('.frm_section_heading');
            //if next section is a conditional section
            if (_next.attr("style") == 'display: none;') {
                setTimeout(function() {

                    if (_next.is(":hidden")) {
                        //if conditional section display is false
                        var _nextVisible = block.nextAll('.frm_section_heading:visible').first();
                        _nextVisible.slideDown().addClass('active');
                    } else {
                        //if conditional section display is true
                        _next.slideDown().addClass('active');
                    }

                }, 200);
            } else {

                //if standard section
                if ((_next.attr("style") == 'display: list-item;') && (_next.hasClass('active'))) {
                    //if next section was visible but is hidden (conditional section)
                    var _nextVisible = _next.nextAll('.frm_section_heading:visible').first();
                    _nextVisible.slideDown().addClass('active');
                } else {
                    //default next section behaviour
                    _next.slideDown().addClass('active');
                }

            }
        }
    }

    function checkNextSection( block ) {

        //console.log(block);
        var _next = block.next('.frm_section_heading'),
        end = false;

        //count number of required fields within next section
        if (end == false) {
            // console.log(countInputs(getRequiredFields( _next )));
            if (countInputs(getRequiredFields( _next )) == "0") {

                if (_next.hasClass('end')) {
                    // console.log('end');
                    showNextSection( block );
                    end = true;
                }

                if (end == false) {
                    showNextSection( block );
                    checkNextSection( _next );
                }

            } else { //if there is required fields, show the section
                showNextSection( block );
            }
        }
    }

    function checkForFormErrors() {
        $ = jQuery;
        var _errorDivs = applicationForm.find('.frm_error');

        if (_errorDivs.length > 0) {
            applicationForm.slideDown();
            applicationForm.removeClass('hide');
            applicationForm.addClass('show');

            var blocks = applicationForm.find('.frm_section_heading');
            blocks.each(function() {
                block = $(this);
                block.slideDown().addClass('active');
            });

            var first_error = _errorDivs.first();

            if ($(window).width() > 767) {
                $("html, body").animate({ scrollTop: first_error.offset().top - 300 }, 500);
            } else {
                $("html, body").animate({ scrollTop: first_error.offset().top - 250 }, 500);
            }
        }
    }

    $(document).ready(function() {
        checkForFormErrors();

        var selector = $('.frm_required_field input, .frm_required_field select, .frm_required_field textarea');

        applicationForm.find('.frm_section_heading').change(function(e) {
            var block = $(this);

            //if required fields within a block are filled in, show next section
            if( isComplete( block ) ) {
                checkNextSection( block );
            }
        });

        applicationForm.find('.frm_section_heading').first().addClass('active');

        $('.frm_required_field input.frm_other_input').attr('placeholder', 'Please State...');
    });
})(jQuery);
